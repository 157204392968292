<template>
  <v-container class="page-content">
    <h1 class="text-center pa-6">DATAFLUCT discovery. 利用規約</h1>
    <div class="content" v-html="content"></div>
  </v-container>
</template>

<script>
import content from "html-loader!../assets/html/tos.html";
export default {
  data() {
    return {
      content
    };
  }
};
</script>
